import moment from 'moment'
export const Format = {
  
  data() {
    return {
      shootSeason: '',
      shootTime:''
    }
  },

  methods: {
    seasonFormat(season) {
      if(season == 1){
        this.shootSeason = "春";
      } else if(season == 2) {
        this.shootSeason = "夏";
      } else if(season == 3) {
        this.shootSeason = "秋";
      } else if(season == 4) {
        this.shootSeason = "冬";
      }
    },
    shootTimeFormat(time) {
      if(time == 1) {
        this.shootTime = "朝";
      } else if(time == 2) {
        this.shootTime = "昼";
      } else if(time == 3) {
        this.shootTime = "夕方";
      } else if(time == 4) {
        this.shootTime = "夜";
      }
    }
  },
  computed: {
    formatDateTime(date) {
      return moment(date).format('YYYY/MM/DD HH:mm')
    }
  },
  filters: {
    formatDate(date) {
      return moment(date).format('YYYY/MM/DD')
    },
    // formatDateTime(date) {
    //   return moment(date).format('YYYY/MM/DD HH:mm')
    // },
    formatJpDate(date) {
      return moment(date).format('YYYY年MM月DD日')
    }
  },
  
        
}