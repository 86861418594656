<template>
  <b-modal
    id="ShowPDFModal"
    size="xl"
    hide-footer
    hide-header-close
    hide-header
  >
  
  <b-col 
    class="mx-auto" 
  >
    <b-link 
      :href="pdfData" 
      :download="pdfData + '.pdf'"
    >
      資料ダウンロード
    </b-link>
    <div 
      style="height: 100%; width:100%;"
    >
      <div 
        style="text-align:right;"
      >
        <h5>
          {{ pdfPage }}/{{ totalPageNum }} 
        </h5>
      </div>
      <b-col 
        style="text-align:right;"
        class="mb-1"
      >
        <b-button 
          :disabled="pdfPage == 1"
          @click="prevPage"
          variant="primary"
        >
          戻る
        </b-button>
        <b-button
          :disabled="totalPageNum == pdfPage"
          class="ms-2"
          @click="nextPage"
          variant="primary"
        >
          次へ
        </b-button>
      </b-col>
      <pdf
        :src="pdfURL"
        :page="pdfPage"
        @num-pages="totalPageNum = $event"
      ></pdf>
      <b-col
        style="text-align:right;"
        class="mt-1"
      >
        <b-button
          @click="closeModal"
        >
          閉じる
        </b-button>
      </b-col>
    </div>
  </b-col>
  </b-modal>
</template>

<script>
import pdf from 'vue-pdf'
export default {
  props:{
    pdfData:String,
    currentPageNum:Number
  },
  data() {
    return {
      totalPageNum: 0,
      pdfPage:0,
      pdfURL:''
    }
  },
  mounted() {
    
  },

  methods: {
    //前へを押されたとき
    prevPage() {
      if (this.pdfPage <= 1) {
        return
      }
      this.pdfPage = this.pdfPage - 1
    },
    //次へを押されたとき
    nextPage() {
      if (this.pdfPage >= this.totalPageNum) {
        return
      }
      this.pdfPage = this.pdfPage + 1
    },
    //モーダルを閉じる
    closeModal() {
      this.$bvModal.hide('ShowPDFModal')
    },
  },
  watch:{
    'pdfData':function() {
      this.pdfPage = this.currentPageNum
      this.pdfURL = pdf.createLoadingTask({
        url: this.pdfData,
        cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.6.347/cmaps/',
        cMapPacked: true,
      });
    }
  }
}


</script>
