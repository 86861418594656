<template>
  <b-modal
    id="ShowEstatePhotoModal"
    size="lg"
    hide-header-close
  >
  <template #modal-header>
    <h5>
			{{ locationName }} - {{ estateName }}
		</h5>
  </template>
    <div>
      <b-img
				style="height:100%;"
        thumbnail 
        center 
        fluid 
        :src="estateImage"
      />
    </div>

  </b-modal>
</template>

<script>
export default {
  props:{
    estateName:String,
    locationName:String,
    estateImage:String,
  },
  mounted() {

  },
methods:{


	}
}


</script>

<style scoped>

</style>