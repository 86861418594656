<template>
  <div>
    <b-modal 
      id="UpdateNoteModal" 
      ok-disabled
      hide-header-close
    >
      <template #modal-header>
        <h5>物件備考更新</h5>
        <b-form-checkbox
            id="checkbox-1"
            v-model="checkNoteAllUpdate"
          >
            チェックの入った物件を一括変更
          </b-form-checkbox>
      </template>
      <b-row>
        <b-col >
          <b-form-textarea
            class="w-100"
            v-model="updateNote"
            rows="6"
          >
          </b-form-textarea>
        </b-col>
      </b-row>
    
      <template #modal-footer>
        <b-row>
          <b-col 
            md="auto"
          >
            <b-button
              variant="primary"
              @click="putUpdateNote"
            >
              更新
            </b-button>
          </b-col>
          <b-col 
            style="text-align:right;"
          >
            <b-button 
              @click="hideModal"
            >
              閉じる
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Apimixin } from '../mixins/api'
export default {
  mixins:[Apimixin],
  props:{
    userID:String,
    estateID:String,
    shootingID:String,
    shootingType:String,
    note:String,
    checkUpdate:[],
    estateLists:[]
  },
  data() {
    return {
      checkNoteAllUpdate:false,
      updateNote:''
    }
  },
  mounted() {
    //ユーザー情報取得
    this.getUsers(this.userID)
  },
  methods:{

    //モーダルクローズ
    hideModal() {
      this.$bvModal.hide('UpdateNoteModal')
    },
    //備考情報更新
    putUpdateNote(userID) {
      let estateVals = []
        
      if(this.checkNoteAllUpdate && this.checkUpdate.length !== 0) {
        for(let updateShootingID of this.checkUpdate) {
          for(let estateList of this.estateLists) {
            if(estateList.inShootingPrpgressID == updateShootingID) {
              estateVals.push({
                shootingPrpgressID:Number(estateList.inShootingPrpgressID),
                estateID:estateList.cEstateID,
                shootingType:Number(this.shootingType),
                note:this.updateNote
              })
            }
          }
        }
      } else {
        console.log(userID)
        estateVals.push({
          shootingPrpgressID:Number(this.shootingID),
          estateID:this.estateID,
          shootingType:Number(this.shootingType),
          note:this.updateNote
        })
      }
      this.$swal({
        title: '処理中',
        html: '処理終了まで画面はそのままにしてください。', 
        allowOutsideClick : false , 
        showConfirmButton: false, 
      });
      this.putEstateList(this.userID,estateVals)
    }
    
  },
  watch: {
    'note':function() {
      this.updateNote = this.note
    }
  }
}
</script>