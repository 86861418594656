<template>
  <div>
    <Header/>
    <b-container
      style="max-width:100%; padding-top:70px; "
    >
      <b-row>
        <b-col>
          <h3
            class="mb-0"
          >
            進捗管理
          </h3>
        </b-col>
      </b-row>
      <b-row
        class="border"
        style="font-size:14px;"
      >
        <b-row

        >
          <label
            class="col-lg-2 col-md-3 col-form-label w-auto"
            for="searchEstateID"
          >
            物件ID:
          </label>
          <b-col
            md="4"
            lg="auto"
            sm="3"
          >
            <b-form-input
              size="sm"
              id="searchEstateID"
              type="text"
              v-model="search.estateID"
            />
          </b-col>
          <label
            class="col-lg-2 col-md-3 col-form-label w-auto"
            for="searchEstateName"
          >
            物件名:
          </label>
          <b-col
            md="4"
            lg="auto"
            sm="5"
          >
            <b-form-input
              size="sm"
              id="searchEstateName"
              type="text"
              v-model="search.estateName"
            />
          </b-col>
          <label
            class="col-lg-2 col-md-3  col-form-label w-auto"
            for="searchLocationID"
          >
            現場ID:
          </label>
          <b-col
            md="4"
            lg="auto"
            sm="3"
          >
            <b-form-input
              size="sm"
              id="searchLocationID"
              type="text"
              v-model="search.locationID"
            />
          </b-col>
          <label
            class="col-lg-2 col-md-3 col-form-label w-auto"
            for="searchLocationName"
          >
            現場名:
          </label>
          <b-col
            md="4"
            lg="auto"
            sm="5"
          >
            <b-form-input
              size="sm"
              id="searchLocationName"
              type="text"
              v-model="search.locationName"
            />
          </b-col>
        </b-row>

        <b-row
          class="mt-1"
        >
          <label
            class="col-lg-2 col-md-3 col-form-label w-auto"
            for="searchCompanyName"
          >
            企業名:
          </label>
          <b-col>
            <b-form-input
              size="sm"
              id="searchCompanyName"
              type="text"
              v-model="search.companyName"
            />
          </b-col>
          <label
            class="col-lg-2 col-md-3 col-form-label w-auto"
            for="searchAddress"
          >
            住所:
          </label>
          <b-col>
            <b-form-input
              size="sm"
              id="searchAddress"
              type="text"
              v-model="search.address"
            />
          </b-col>
        </b-row>

        <dl
          class="mt-1 mb-0 row"
        >
          <b-col
            lg="2"
            md="3"
            class=" w-auto"
          >
            <dd>
              状態:
            </dd>
          </b-col>
          <b-col
            lg="auto"
          >
            <dd>
              <div
                v-for="(status,statusIndex ) in estateStatus"
                :key="statusIndex + status.value"
                class="text-nowrap form-check-inline"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="status.value"
                  :value="status.id"
                  v-model="search.statusCode"
                >
                <label
                  class="form-check-label"
                  :for="status.value"
                >
                  {{ status.value }}
                </label>
              </div>
            </dd>
          </b-col>
        </dl>

        <dl
          class="mt-1 mb-0 row"
        >
          <b-col
            lg="2"
            md="3"
            class=" w-auto"
          >
            <dd>
              進捗:
            </dd>
          </b-col>
          <b-col
            lg="auto"
          >
            <dd>
              <div
                v-for="progress in shootProgress"
                :key="progress.id + progress.value"
                class="text-nowrap form-check-inline"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="progress.value"
                  :value="progress.id"
                  v-model="search.progress"
                >
                <label
                  class="form-check-label"
                  :for="progress.value"
                >
                  {{ progress.value }}
                </label>
              </div>
            </dd>
          </b-col>
        </dl>

        <b-row
          class="mt-1"
        >
          <label
            class="col-lg-2 col-md-3 col-form-label w-auto"
          >
            担当者:
          </label>
          <b-col
            lg="2"
            md="3"
          >
            <select
              class="form-select mx-auto"
              id="searchUser"
              v-model="search.user"
            >
              <option
                value=""
              >
                全件
              </option>
              <option
                :value="usersData"
              >
                全ユーザー
              </option>
              <option
                v-for="user in usersData"
                :key="'user' + user.inUserID"
                :value="user.inUserID"
              >
                {{ user.vcUserLName }}
              </option>
            </select>
          </b-col>
          <b-col
            md="9"
          >
            <dl
              class="row"
            >
              <dd
                class="col-md-2 col-lg-2 w-auto"
              >
                種別:
              </dd>
              <dd
                class="col-md-10 col-lg-10"
              >
                <div
                  v-for="estateKind in estateKinds"
                  :key="estateKind.id"
                  class="text-nowrap form-check-inline"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="estateKind.id"
                    :value="estateKind.id"
                    v-model="search.estateKinds"
                  >
                  <label
                    class="form-check-label"
                    :for="estateKind.id"
                  >
                    {{ estateKind.value }}
                  </label>
                </div>
              </dd>
            </dl>
          </b-col>
        </b-row>

        <b-row
          :hidden="!typeExteriorFlag"
        >
          <b-col
            md="12"
            cols="12"
          >
            <dl
              class="row "
            >
              <dd
                class="col-md-1 cols-1 w-auto"
              >
                建築工程:
              </dd>
              <dd
                class="col-md-11 cols-10 w-auto"
              >
                <div
                  v-for="selectProcesse in selectProcesses"
                  :key="selectProcesse.value"
                  class="text-nowrap form-check-inline"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="selectProcesse.value"
                    :value="selectProcesse.id"
                    v-model="search.process"
                  >
                  <label
                    class="form-check-label"
                    :for="selectProcesse.value"
                  >
                    {{ selectProcesse.value }}
                  </label>
                </div>
              </dd>
            </dl>
          </b-col>
          <b-col
            md="4"
            cols="12"
            hidden
          >
            <dl
              class="row"
            >
              <dd
                class="col-md-3 cols-1 w-auto"
              >
                撮影番号:
              </dd>
              <dd
                class="col col-md-3 cols-auto w-auto"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="photoNO"
                >
                <label
                  class="form-check-label"
                  for="photoNO"
                >
                  最新の撮影番号のみ
                </label>
              </dd>
            </dl>
          </b-col>
        </b-row>

        <b-row>
          <label
            class="col-lg-2 col-md-3 col-form-label w-auto"
          >
            撮影予定日:
          </label>
          <b-col
            md="3"
            sm="4"
            class="form-floating"
          >
            <b-form-input

              id="shootFrom"
              type="date"
              v-model="search.searchFrom"
            />
            <label
              for="shootFrom"
            >
              from
            </label>
          </b-col>
          <b-col
            md="3"
            sm="4"
            class="form-floating"
          >
            <b-form-input
              id="shootTo"
              type="date"
              v-model="search.searchTo"
            />
            <label
              for="shootTo"
            >
              to
            </label>
          </b-col>
        </b-row>

        <b-row
          class="mt-1"
        >
          <label
            class="col-lg-2 col-md-2 col-sm-1 col-form-label w-auto"
            for="note"
          >
            備考:
          </label>
          <b-col
            md="3"
            sm="5"
          >
            <b-form-input
              size="sm"
              id="note"
              type="text"
              v-model="search.note"
            />
          </b-col>
          <b-col
            md="4"
            sm="auto"
            class="w-auto"
          >
            <dl
              class="row"
            >
              <dd
                class="col-md-3 col-sm-2 w-auto"
              >
                絞り込み:
              </dd>
              <dd
                class="col-sm-auto col-md-3 w-auto"
              >
                <div
                  v-for="(checkContent,index) in checkContents"
                  :key="index"
                  class="text-nowrap form-check-inline"
                >
                  <input
                    v-model="search.changeContent"
                    class="form-check-radio"
                    type="radio"
                    :id="checkContent.value"
                    :value="checkContent.id"
                  >
                    <label
                      class="form-check-label"
                      :for="checkContent.value"
                    >
                    {{ checkContent.value }}
                  </label>
                </div>
              </dd>

              <dd
                class="col-sm-auto col-md-3 w-auto"
              >
                <div
                  v-for="(type,typeIndex) in checkType"
                  :key="typeIndex"
                  class="text-nowrap form-check-inline"
                >
                  <input
                    v-model="search.checkType"
                    class="form-check-radio"
                    type="radio"
                    :id="type.value"
                    :value="type.id"
                  >
                  <label
                    class="form-check-label"
                    :for="type.value"
                  >
                    {{ type.text }}
                  </label>
                </div>
              </dd>
            </dl>

          </b-col>
          <b-col
            md="3"
            cols="12"
            sm="12"
          >
            <dl
              class="row"
            >
              <dd
                class="col-md-2 col-lg-2 cols-2 w-auto"
              >
                マイソク:
              </dd>
              <dd
                class="col-md-5 cols-4 col-sm-5"
              >
                <div
                  v-for="checkDocument in checkDocuments"
                  :key="checkDocument.id"
                  class="text-nowrap form-check-inline"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="checkDocument.value"
                    :value="checkDocument.id"
                    v-model="search.document"
                  >
                  <label
                    class="form-check-label"
                    :for="checkDocument.value"
                  >
                    {{ checkDocument.value }}
                  </label>
                </div>
              </dd>
            </dl>
          </b-col>
          <!--<b-col-->
            <!--md="2"-->
            <!--cols="5"-->
          <!--&gt;-->
            <!--<dl-->
              <!--class="row"-->
            <!--&gt;-->
              <!--<dd-->
                <!--class="col-md-2 col-lg-2 cols-2 w-auto"-->
              <!--&gt;-->
                <!--検索上限件数:-->
              <!--</dd>-->

                <!--<select-->
                  <!--class="form-select w-auto"-->
                  <!--name="searchLimits"-->
                  <!--v-model="search.searchLimit"-->
                <!--&gt;-->
                  <!--<option-->
                    <!--v-for="searchLimit in searchLimits"-->
                    <!--:key="'searchLimit-' +searchLimit.id"-->
                    <!--:value="searchLimit.value"-->
                  <!--&gt;-->
                    <!--{{ searchLimit.text }}-->
                  <!--</option>-->
                <!--</select>-->
            <!--</dl>-->
          <!--</b-col>-->

        </b-row>
        <b-row>
          <b-col
            md="9"
          >
            <b-button
              variant="warning"
              @click="clearList"
            >
              クリア
            </b-button>
          </b-col>
          <b-col
            md="auto"
            sm="auto"
          >
            <b-button
              variant="success"
              @click="searchEstateList"
            >
              絞り込み
            </b-button>
          </b-col>
        </b-row>
      </b-row>

      <b-row
        class=" mt-2 show-checkbox "
        style="font-size:14px;  "
        ref="stickycontents"
      >
        <dl
          class="row mb-0"
        >
          <dd
            class="col-lg-2 col-md-2 col-sm-1 w-auto"
          >
            表示:
          </dd>
          <dd
            class="col-lg-10 col-md-9 col-sm-10"
          >
            <div
              v-for="tableHeader in tableHeaders"
              :key="tableHeader.id + tableHeader.value"
              class="text-nowrap form-check-inline"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :id="tableHeader.id + tableHeader.value"
                :value="tableHeader.showHeader"
                v-model="tableHeader.showHeader"
              >
              <label
                class="form-check-label"
                :for="tableHeader.id + tableHeader.value"
              >
                {{ tableHeader.text }}
              </label>
            </div>
          </dd>
          <dd
            class="col-md-2 col-sm-1 w-auto "
          >
            <b-button
              size="sm"
              @click="showAllHeaders"
            >
              すべて表示
            </b-button>
          </dd>
        </dl>
      <!-- </b-row>

      <b-row
        class="mb-2 mt-2 info-area"
      > -->
      <b-row>
        <b-col
          md="1"
          cols="1"
          class="w-auto"
        >
          <span
            v-if="locationListFlag"
          >
            <b>
            表示件数:{{ locationCount }}件
            </b>
          </span>
          <span
            v-else
          >
            <b>
            表示件数:{{ estateCount }}件
            </b>
          </span>
          <span>
            ( 物件数:{{ estateCount }},現場数:{{ locationCount }} )
          </span>

        </b-col>
        <b-col
          md="4"
          cols="3"
        >
          <paginate
            :page-count="getPaginateCount"
            :prev-text="'<'"
            :next-text="'>'"
            :click-handler="paginateClickCallback"
            :container-class="'pagination justify-content-center'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-class="'page-item'"
            :prev-link-class="'page-link'"
            :next-class="'page-item'"
            :next-link-class="'page-link'"
            :first-last-button="true"
            :first-button-text="'<<'"
            :last-button-text="'>>'"
            v-model="currentPage"
          >
          </paginate>
        </b-col>
        <b-col
          md="2"
          cols="5"
        >
          <select
            class="form-select w-auto"
            name="displayLimits"
            v-model="display"
          >
            <option
              v-for="displayLimit in displayLimits"
              :key="'displayLimit-' +displayLimit.id"
              :value="displayLimit.value"
            >
              {{ displayLimit.text }}
            </option>
          </select>
        </b-col>
        <b-col
          md="2"
          cols="6"
          class="w-auto"
        >
          <b-button
            variant="primary"
            @click="changeLimit"
          >
            表示件数変更
          </b-button>
        </b-col>
        <b-col
          md="2"
          cols="6"
        >
          <b-button
            variant="outline-info"
            @click="getCSVData"
          >
            CSV出力
          </b-button>
        </b-col>
      </b-row>
      <b-overlay
        :show="showLoaderSecond"
      >
        <dl
          ref="title"
          class="row mb-0"
          style="font-size:13px; "
        >
          <dd
            class="col-lg-2 col-md-2 col-sm-1 w-auto"
          >
            市の一覧:
          </dd>
          <dd
            v-for="(addressList,addressListIndex) in addressLists"
            :key="'address' + addressListIndex"
            class="col-lg-1 col-md-1 col-sm-1  w-auto"

          >
          <b-link
            @click="searchAddress(addressList.vcCityName)"
          >
            {{ addressList.vcCityName }}({{ addressList.EstateCount }},{{ addressList.LocationCount }})
          </b-link>
          </dd>
        </dl>
      </b-overlay>
    </b-row>


      <span
        class="text-danger"
        v-if="error.getEstate !== ''"
      >
        {{ error.getEstate }}
      </span>

      <b-overlay
        :show="showLoader"
        z-index="10"
      >
        <b-table-simple
          class="table table_sticky"
          style="font-size:13px;max-width:100%; "
          responsive="true"
          id="tabletop"
          bordered
        >
          <b-thead
            class="table-secondary"
            head-variant="secondary"
          >
            <b-tr>
              <b-th
                scope="col"
                width="1%"
              >
                <input
                  name="allCheck"
                  class="form-check-input"
                  type="checkbox"
                  @click="selectAllIntrospection"
                  :checked="isEstateAllSelect"
                >
              </b-th>
              <b-th
                scope="col"
                v-show="tableHeaders[0].showHeader"
                class="text-nowrap"
              >
                撮影<br>予定日
                <br>
                <b-icon
                  :variant="sort[0].shootingDateasc ? 'primary' : 'secondary'"
                  @click="sortEstateList('shootingDate','asc')"
                  :icon="arrowUpIcon"
                  scale="2"
                  class="btn_link"
                ></b-icon>
                <b-icon
                  :variant="sort[1].shootingDatedesc ? 'primary' : 'secondary'"
                  class="ms-2 btn_link"
                  @click="sortEstateList('shootingDate','desc')"
                  :icon="arrowDownIcon"
                  scale="2"
                ></b-icon>
              </b-th>
              <b-th
                scope="col"
                class=text-nowrap
                v-show="tableHeaders[1].showHeader"
              >
                前回
                <br>
                撮影日
                <br>
                <b-icon
                  :variant="sort[2].lastShootingDateasc ? 'primary' : 'secondary'"
                  @click="sortEstateList('lastShootingDate','asc')"
                  :icon="arrowUpIcon"
                  scale="2"
                  class="btn_link"
                ></b-icon>
                <b-icon
                  :variant="sort[3].lastShootingDatedesc ? 'primary' : 'secondary'"
                  class="ms-2 btn_link"
                  @click="sortEstateList('lastShootingDate','desc')"
                  :icon="arrowDownIcon"
                  scale="2"
                ></b-icon>
              </b-th>
              <b-th
                scope="col"
                class="text-nowrap"
                v-show="tableHeaders[2].showHeader"
              >
                経過
                <br>
                日数
                <br>
                <b-icon
                  :variant="sort[4].datediffasc ? 'primary' : 'secondary'"
                  @click="sortEstateList('datediff','asc')"
                  :icon="arrowUpIcon"
                  scale="2"
                  class="btn_link"
                ></b-icon>
                <b-icon
                  :variant="sort[5].datediffdesc ? 'primary' : 'secondary'"
                  class="ms-2 btn_link"
                  @click="sortEstateList('datediff','desc')"
                  :icon="arrowDownIcon"
                  scale="2"
                ></b-icon>
              </b-th>
              <b-th
                scope="col"
                v-show="tableHeaders[3].showHeader"
                class="text-nowrap"
              >
                物件
                <br>
                登録日
                <br>
                <b-icon
                  :variant="sort[6].createDateasc ? 'primary' : 'secondary'"
                  @click="sortEstateList('createDate','asc')"
                  :icon="arrowUpIcon"
                  scale="2"
                  class="btn_link"
                ></b-icon>
                <b-icon
                  :variant="sort[7].createDatedesc ? 'primary' : 'secondary'"
                  class="ms-2 btn_link"
                  @click="sortEstateList('createDate','desc')"
                  :icon="arrowDownIcon"
                  scale="2"
                ></b-icon>
              </b-th>
              <b-th
                scope="col"
                class="text-nowrap"
                v-show="tableHeaders[4].showHeader"
              >
                物件ID
                <br>
                <b-icon
                  :variant="sort[8].estateIDasc ? 'primary' : 'secondary'"
                  @click="sortEstateList('estateID','asc')"
                  :icon="arrowUpIcon"
                  scale="2"
                  class="btn_link"
                ></b-icon>
                <b-icon
                  :variant="sort[9].estateIDdesc ? 'primary' : 'secondary'"
                  class="ms-2 btn_link"
                  @click="sortEstateList('estateID','desc')"
                  :icon="arrowDownIcon"
                  scale="2"
                ></b-icon>
              </b-th>
              <b-th
                scope="col"
                width="7%"
                class="text-nowrap"
                v-show="tableHeaders[5].showHeader"
              >
                物件名
              </b-th>
              <b-th
                scope="col"
                class="text-nowrap"
                v-show="tableHeaders[6].showHeader"
              >
                号棟
                <br>
                /区画
                <br>
                番号
                <br>
                <b-icon
                  :variant="sort[10].fixNumberasc ? 'primary' : 'secondary'"
                  @click="sortEstateList('fixNumber','asc')"
                  :icon="arrowUpIcon"
                  scale="2"
                  class="btn_link"
                ></b-icon>
                <b-icon
                  :variant="sort[11].fixNumberdesc ? 'primary' : 'secondary'"
                  class="ms-2 btn_link"
                  @click="sortEstateList('fixNumber','desc')"
                  :icon="arrowDownIcon"
                  scale="2"
                ></b-icon>
              </b-th>
              <b-th
                scope="col"
                class="text-nowrap"
                v-show="tableHeaders[7].showHeader"
              >
                現場ID
                <br>
                <b-icon
                  :variant="sort[12].locationIDasc ? 'primary' : 'secondary'"
                  @click="sortEstateList('locationID','asc')"
                  :icon="arrowUpIcon"
                  scale="2"
                  class="btn_link"
                ></b-icon>
                <b-icon
                  :variant="sort[13].locationIDdesc ? 'primary' : 'secondary'"
                  class="ms-2 btn_link"
                  @click="sortEstateList('locationID','desc')"
                  :icon="arrowDownIcon"
                  scale="2"
                ></b-icon>
              </b-th>
              <b-th
                scope="col"
                width="7%"
                class="text-nowrap"
                v-show="tableHeaders[8].showHeader"
              >
                現場名
              </b-th>
              <b-th
                scope="col"
                width="7%"
                class="text-nowrap"
                v-show="tableHeaders[9].showHeader"
              >
                住所
              </b-th>
              <b-th
                scope="col"
                class="text-nowrap"
                v-show="tableHeaders[10].showHeader"
              >
                企業名
                <br>
                <b-icon
                  :variant="sort[14].companyNameasc ? 'primary' : 'secondary'"
                  @click="sortEstateList('companyName','asc')"
                  :icon="arrowUpIcon"
                  scale="2"
                  class="btn_link"
                ></b-icon>
                <b-icon
                  :variant="sort[15].companyNamedesc ? 'primary' : 'secondary'"
                  class="ms-2 btn_link"
                  @click="sortEstateList('companyName','desc')"
                  :icon="arrowDownIcon"
                  scale="2"
                ></b-icon>
              </b-th>
              <b-th
                scope="col"
                class="text-nowrap"
                v-show="tableHeaders[11].showHeader"
              >
                種別
                <br>
                <b-icon
                  :variant="sort[16].estateKindasc ? 'primary' : 'secondary'"
                  @click="sortEstateList('estateKind','asc')"
                  :icon="arrowUpIcon"
                  scale="2"
                  class="btn_link"
                ></b-icon>
                <b-icon
                  :variant="sort[17].estateKinddesc ? 'primary' : 'secondary'"
                  class="ms-2 btn_link"
                  @click="sortEstateList('estateKind','desc')"
                  :icon="arrowDownIcon"
                  scale="2"
                ></b-icon>
              </b-th>
              <b-th
                scope="col"
                class="text-nowrap"
                v-show="tableHeaders[12].showHeader"
              >
                状態
              </b-th>
              <b-th
                scope="col"
                v-show="tableHeaders[13].showHeader"
              >
                撮影
                <br>
                番号
                <br>
                <b-icon
                  :variant="sort[24].shooteingCountdesc ? 'primary' : 'secondary'"
                  class="ms-2 btn_link"
                  @click="sortEstateList('shooteingCount','desc')"
                  :icon="arrowDownIcon"
                  scale="2"
                ></b-icon>
              </b-th>
              <b-th
                scope="col"
                class="text-nowrap"
                v-show="tableHeaders[14].showHeader"
              >
                建築
                <br>
                工程
                <br>
                <b-icon
                  :variant="sort[18].processIDasc ? 'primary' : 'secondary'"
                  @click="sortEstateList('processID','asc')"
                  :icon="arrowUpIcon"
                  scale="2"
                  class="btn_link"
                ></b-icon>
                <b-icon
                  :variant="sort[19].processIDdesc ? 'primary' : 'secondary'"
                  class="ms-2 btn_link"
                  @click="sortEstateList('processID','desc')"
                  :icon="arrowDownIcon"
                  scale="2"
                ></b-icon>
              </b-th>
              <b-th
                scope="col"
                class="text-nowrap"
                v-show="tableHeaders[15].showHeader"
              >
                進捗
                <br>
                <b-icon
                  :variant="sort[20].inProgressIDasc ? 'primary' : 'secondary'"
                  @click="sortEstateList('inProgressID','asc')"
                  :icon="arrowUpIcon"
                  scale="2"
                  class="btn_link"
                ></b-icon>
                <b-icon
                  :variant="sort[21].inProgressIDdesc ? 'primary' : 'secondary'"
                  class="ms-2 btn_link"
                  @click="sortEstateList('inProgressID','desc')"
                  :icon="arrowDownIcon"
                  scale="2"
                ></b-icon>
              </b-th>
              <b-th
                scope="col"
                class="text-nowrap"
                v-show="tableHeaders[16].showHeader"
              >
                撮影者
              </b-th>
              <b-th
                scope="col"
                width="10%"
                v-show="tableHeaders[17].showHeader"
              >
                連絡方法
              </b-th>
              <b-th
                scope="col"
                width="10%"
                v-show="tableHeaders[18].showHeader"
              >
                鍵情報
              </b-th>
              <b-th
                scope="col"
                width="10%"
                class="text-nowrap"
                v-show="tableHeaders[19].showHeader"
              >
                備考
                <br>
                <b-icon
                  :variant="sort[22].remarksasc ? 'primary' : 'secondary'"
                  @click="sortEstateList('remarks','asc')"
                  :icon="arrowUpIcon"
                  scale="2"
                  class="btn_link"
                ></b-icon>
                <b-icon
                  :variant="sort[23].remarksdesc ? 'primary' : 'secondary'"
                  class="ms-2 btn_link"
                  @click="sortEstateList('remarks','desc')"
                  :icon="arrowDownIcon"
                  scale="2"
                ></b-icon>
              </b-th>
              <b-th
                scope="col"

                v-show="tableHeaders[20].showHeader"
              >
                前回の写真
              </b-th>
              <b-th
                scope="col"
                width="1%"
                v-show="tableHeaders[21].showHeader"
              >
                業プロ
              </b-th>
              <b-th
                scope="col"
                width="1%"
                v-show="tableHeaders[22].showHeader"
              >
                マイソク
              </b-th>
              <b-th
                scope="col"
                width="7%"
              >

              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody

          >
            <b-tr
              v-for="(estateList, estateIndex) in estateLists"
              :key="estateIndex"
            >
              <b-td>
                <b>
                  {{ estateIndex + 1 }}
                </b><br>
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="estateList.inShootingPrpgressID"
                  v-model="checkUpdate"
                >
              </b-td>
              <b-td
                v-show="tableHeaders[0].showHeader"
                class=" table_link"
                @click="showShootDateModal(
                  estateList.cEstateID,
                  estateList.inShootingPrpgressID,
                  estateList.dtShootingDate,
                  estateList.inProgressID
                  )"
              >
                {{ estateList.dtShootingDate  }}
              </b-td>
              <b-td
                v-show="tableHeaders[1].showHeader"
                class="text-nowrap"
              >
                {{ estateList.dtLastTime }}
              </b-td>
              <b-td
                v-show="tableHeaders[2].showHeader"
                class="text-center"
              >
                {{ estateList.datediff }}日
              </b-td>
              <!-- <b-td
                v-show="tableHeaders[3].showHeader"
              >
                {{ estateList.nextShootingDate }}
              </b-td> -->
              <b-td
                v-show="tableHeaders[3].showHeader"
              >
                {{ estateList.dtCreate }}
              </b-td>
              <b-td
                v-show="tableHeaders[4].showHeader"
              >
                {{ estateList.cEstateID }}
              </b-td>
              <b-td
                v-show="tableHeaders[5].showHeader"
              >
                {{ estateList.vcEstateName }}
              </b-td>
              <b-td
                v-show="tableHeaders[6].showHeader"
              >
                {{ estateList.vcFixNumber }}
              </b-td>
              <b-td
                v-show="tableHeaders[7].showHeader"
              >
                {{ estateList.cLocationID }}
              </b-td>
              <b-td
                v-show="tableHeaders[8].showHeader"
              >
                {{ estateList.vcLocationName }}
              </b-td>
              <b-td
                v-show="tableHeaders[9].showHeader"
              >
                {{ estateList.vcAddress }}
                <br>
                <b-link
                  v-if="estateList.doLatitude != null && estateList.doLongitude != null "
                  :href="googleMapLink + estateList.doLatitude+',' + estateList.doLongitude"
                  target="_blank"
                >
                  GoogleMapへ
                </b-link>
              </b-td>
              <b-td
                v-show="tableHeaders[10].showHeader"
              >
                {{ estateList.vcCompanyName }}
              </b-td>
              <b-td
                v-show="tableHeaders[11].showHeader"
              >
                {{ estateList.vcEstateKindName }}
              </b-td>
              <b-td
                v-show="tableHeaders[12].showHeader"
              >
                {{ estateList.vcEstateStateName }}
              </b-td>
              <b-td
                v-show="tableHeaders[13].showHeader"
              >
                <b-link
                  @click="getShootNo(
                    estateList.cEstateID
                  )"
                >
                  {{ estateList.cnt }}
                </b-link>
              </b-td>
              <b-td
                v-show="tableHeaders[14].showHeader"
              >
                <span
                  v-if="estateList.tiShootingType == 1"
                >
                  {{ estateList.introProcessType }}
                </span>
                <span
                  v-if="estateList.tiShootingType == 0"
                >
                  {{ estateList.vcProcessName }}
                </span>
              </b-td>
              <b-td
                v-show="tableHeaders[15].showHeader"
              >
                {{ estateList.vcProgressName }}
              </b-td>
              <b-td
                v-show="tableHeaders[16].showHeader"
                class="text-center table_link"
                @click="showUserModal(
                  estateList.cEstateID,
                  estateList.inShootingPrpgressID,
                  estateList.tiShootingType
                  )"
              >
                {{ estateList.vcUserLName }}
              </b-td>
              <b-td
                v-show="tableHeaders[17].showHeader"
                style="white-space: pre-wrap;"
              >
              <!-- <b-form-textarea
                v-model="estateList.txContactAddress"
                readonly
                class="w-auto"
              >
              </b-form-textarea> -->
                {{ estateList.txContactAddress }}
              </b-td>
              <b-td
                style="white-space: pre-wrap;"
                v-show="tableHeaders[18].showHeader"
              >
                {{ estateList.txKeyInformation }}
              </b-td>
              <b-td
                v-if="estateList.tiShootingType == 1"
                style="white-space: pre-wrap;"
                v-show="tableHeaders[19].showHeader"
                class=" table_link"
                @click="showNoteModal(
                  estateList.cEstateID,
                  estateList.inShootingPrpgressID,
                  estateList.txInterior,
                  estateList.tiShootingType
                )"
              >
                {{ estateList.txInterior }}
              </b-td>
              <b-td
                v-else-if="estateList.tiShootingType == 0"
                style="white-space: pre-wrap;"
                v-show="tableHeaders[19].showHeader"
                class=" table_link"
                @click="showNoteModal(
                  estateList.cEstateID,
                  estateList.inShootingPrpgressID,
                  estateList.txExterior,
                  estateList.tiShootingType
                )"
              >
              {{ estateList.txExterior }}
              </b-td>
              <b-td
                v-show="tableHeaders[20].showHeader"
              >
                <div>
                  <b-img
                    :class="{'img_link' : estateList.vcStorage != noImage  }"
                    style="width: 120px;height: 100px;"
                    :src="estateList.vcStorage"
                    @click="showEstateImage(
                      estateList.vcStorage,
                      estateList.vcLocationName,
                      estateList.vcEstateName,
                    )"
                  />
                </div>
              </b-td>
              <b-td
                v-show="tableHeaders[21].showHeader"
              >
                <b-link
                  :href="gyoproURL + estateList.cEstateID"
                  target="_blank"
                >
                  業
                </b-link>
                <br>
                <br>
                <b-link
                  :href="gyozeroURL + estateList.cEstateID"
                  target="_blank"
                >
                  ゼ
                </b-link>
              </b-td>
              <b-td
                v-show="tableHeaders[22].showHeader"

              >
                <div
                  v-for="(materialList,materialListID) in estateList.material"
                  :key="materialListID"
                >
                  <span>
                    <b
                      v-if=" materialList.vcMaterialID != null && !materialList.vcMaterialID.includes('zip')"
                      class="btn_link"
                      @click="ShowPDFModal(materialList.vcMaterialID)"
                    >
                      {{ materialList.materialName }}
                    </b>
                    <b
                      v-else-if="materialList.vcMaterialID != null && materialList.vcMaterialID.includes('zip')"
                      class="btn_link"
                    >
                      <b-link
                        :href="materialList.vcMaterialID"
                        download
                      >
                        {{ materialList.materialName }}(zip)
                      </b-link>
                    </b>
                    <s
                      v-else
                    >

                    </s>
                  </span>
                </div>
              </b-td>

              <b-td>
                <span
                  v-if="estateList.tiShootingType == 1 "
                >
                  <b-button
                    class="text-nowrap"
                    size="sm"
                    variant="success"
                    @click="pushEstatePhotoForm(
                      estateList.cEstateID,
                      estateList.cLocationID,
                      estateList.tiShootingType,
                      estateList.dtShootingDate,
                      estateList.inUserID,
                      estateList.inShootingPrpgressID
                      )"
                  >
                    内観登録
                  </b-button>
                </span>
                <span
                  v-else-if="estateList.tiShootingType == 0 "
                >
                  <b-button
                    class="mt-1 text-nowrap"
                    size="sm"
                    variant="primary"
                    @click="pushEstatePhotoForm(
                      estateList.cEstateID,
                      estateList.cLocationID,
                      estateList.tiShootingType,
                      estateList.dtShootingDate,
                      null,
                      estateList.inProgressID,
                      estateList.tiTypeCd,
                      estateList.inShootingPrpgressID,
                      estateList.inProcessID
                      )"
                  >
                    外観登録
                  </b-button>
                </span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-overlay>
      <ShowPDFModal
        :pdfData="pdfData"
        :currentPageNum="pdfCurrentPage"
      />
      <UpdateUserModal
        :userID="userID"
        :estateID="selectEstateID"
        :shootingID="selectShootingProgressID"
        :shootingType="selectShootingType"
        :checkUpdate="checkUpdate"
        :updatePageType="updatePageType"
        @afterUpdate="handleAfterUpdate"
      />
      <UpdateShootScheduleModal
        :userID="userID"
        :estateID="selectEstateID"
        :shootingID="selectShootingProgressID"
        :shootDate="selectShootDate"
        :checkUpdate="checkUpdate"
        :progressID="selectProgressID"
        :toDay="toDay"
        @afterUpdate="handleAfterUpdate"
      />
      <UpdateNoteModal
        :userID="userID"
        :estateID="selectEstateID"
        :note="selectNote"
        :shootingID="selectShootingProgressID"
        :estateLists="estateLists"
        :shootingType="selectShootingType"
        :checkUpdate="checkUpdate"
        @afterUpdate="handleAfterUpdate"
      />
      <ShowEstatePhotoModal
        :estateName="photoEstateName"
        :locationName="photoLocationName"
        :estateImage="photoEstateImage"
      />
    </b-container>
  </div>
</template>

<script>
import Header from '../../components/Header.vue'
import ShowPDFModal from '../../components/ShowPDFModal.vue'
import ShowEstatePhotoModal from '../../components/ShowEstatePhotoModal.vue'
import UpdateUserModal from '../../components/UpdateUserModal.vue'
import UpdateShootScheduleModal from '../../components/UpdateShootScheduleModal.vue'
import UpdateNoteModal from '../../components/UpdateNoteModal.vue'
import moment from 'moment'
import aws from 'aws-sdk'
import fileSaver from 'file-saver'
// import JsZip from 'jszip'
import { Apimixin } from '../../mixins/api'
import { Format } from '../../mixins/format'

export default {
  title: '進捗管理',
  components: {
    Header,
    ShowPDFModal,
    UpdateUserModal,
    UpdateShootScheduleModal,
    UpdateNoteModal,
    ShowEstatePhotoModal
  },
  mixins:[Apimixin, Format],
  data() {
    return {
      s3:'',
      // 進捗管理画面は業ゼロ DBを参照する。
      s3Bucket:'',
      arrowUpIcon:'file-arrow-up-fill',
      arrowDownIcon:'file-arrow-down-fill',
      aspect: '4:3',
      showPDF:'/pdfurl/',
      pdfCurrentPage:0,
      pdfData:'',
      userID:'',
      toDay:'',
      checkUpdate:[],
      isEstateAllSelect:false,
      tableHeaders:[
        {id:0,text:'撮影予定日',value:'shootSchedule',showHeader:true,type:'intro' },
        {id:1,text:'前回撮影日',value:'shootDate',showHeader:true,type:'exterior' },
        {id:2,text:'経過日数',value:'datediff',showHeader:true,type:'exterior' },
        {id:3,text:'物件登録日',value:'regist',showHeader:true,type:'intro' },
        {id:4,text:'物件ID',value:'propertyID',showHeader:true,type:'intro' },
        {id:5,text:'物件名',value:'propertyName',showHeader:true,type:'intro' },
        {id:6,text:'号室/区画番号',value:'roomNo',showHeader:true,type:'intro' },
        {id:7,text:'現場ID',value:'locationID',showHeader:true,type:'intro' },
        {id:8,text:'現場名',value:'locationName',showHeader:true,type:'intro' },
        {id:9,text:'住所',value:'address',showHeader:true,type:'intro' },
        {id:10,text:'企業名',value:'companyName',showHeader:true,type:'intro' },
        {id:11,text:'種別',value:'estateKind',showHeader:true,type:'intro' },
        {id:12,text:'状態',value:'approval',showHeader:true,type:'intro' },
        {id:13,text:'撮影番号',value:'shootNO',showHeader:true,type:'exterior' },
        {id:14,text:'建築工程',value:'process',showHeader:true,type:'intro' },
        {id:15,text:'進捗',value:'progress',showHeader:true,type:'intro' },
        {id:16,text:'撮影者',value:'photographer',showHeader:true,type:'intro' },
        {id:17,text:'連絡方法',value:'contactAddress',showHeader:true,type:'intro'},
        {id:18,text:'鍵情報',value:'keyInformation',showHeader:true,type:'intro' },
        {id:19,text:'備考',value:'note',showHeader:true,type:'intro' },
        {id:20,text:'前回の写真',value:'lastImage',showHeader:true,type:'intro' },
        {id:21,text:'業プロ/業ゼロ',value:'link',showHeader:true,type:'intro' },
        {id:22,text:'マイソク',value:'guide',showHeader:true,type:'intro' },
      ],
      search:
        {
          user:'',
          searchFrom:'',
          searchTo:'',
          estateID:'',
          estateName:'',
          process:[],
          locationID:'',
          locationName:'',
          companyName:'',
          address:'',
          statusCode:[],
          progress:[],
          estateKinds:[],
          changeContent:'',
          checkType:'',
          note:'',
          document:[]
          // searchLimit:'500',
          // searchLimits:[]
        },
        searchSort:[],
      sort: [
        { shootingDateasc:false },
        { shootingDatedesc:false },
        { lastShootingDateasc:false },
        { lastShootingDatedesc:false },
        { datediffasc:false },
        { datediffdesc:false },
        { createDateasc:false },
        { createDatedesc:false },
        { estateIDasc:false },
        { estateIDdesc:false },
        { fixNumberasc:false },
        { fixNumberdesc:false },
        { locationIDasc:false },
        { locationIDdesc:false },
        { companyNameasc:false },
        { companyNamedesc:false },
        { estateKindasc:false },
        { estateKinddesc:false },
        { processIDasc:false },
        { processIDdesc:false },
        { inProgressIDasc:false },
        { inProgressIDdesc:false },
        { remarksasc:false },
        { remarksdesc:false },
        { shooteingCountdesc:false },
      ],
      showHeaders:[],
      selectEstateID:'',
      selectShootDate:'',
      estateCount:'',
      locationCount:'',
      estateTotalCount:'',
      estateDisplayCount:'',
      addressLists:[],
      limit:100,
      currentPage:1,
      display:'',
      checkContents:[
        {id:1,value:'物件'},
        {id:2,value:'現場'},
      ],
      checkDocuments:[
        {id:1,value:'有'},
        {id:2,value:'無'},
      ],
      checkType:[
        {id:0,text:'外観',value:'exterior'},
        {id:1,text:'内観',value:'intro'},
      ],
      estateKinds:[
        { id:1,	value:'土地' },
        { id:2,	value:'戸建て' },
        { id:3,	value:'マンション' },
      ],
      // searchLimits:[
      //   {id:1, value:500, text:'500件'},
      //   {id:2, value:1000, text:'1000件'},
      //   {id:3, value:5000, text:'5000件'},
      //   {id:4, value:10000, text:'10000件'},
      //   {id:5, value:50000, text:'50000件'}
      // ],
      selectDay:'',
      typeIntroFlag:false,
      typeExteriorFlag:false,
      searchOption:false,
      selectStartDate:'',
      selectEndDate:'',
      selectShootingProgressID:'',
      selectNote:'',
      selectShootingType:'',
      selectProgressID:0,
      estateLists:[],
      photoLocationName:'',
      photoEstateName:'',
      photoEstateImage:'',
      updatePageType:'',
      error:{
        getEstate:''
      },
      fromCalendarFlag:false,
      isSessionStorageFlag:false,
      isSessionSortFlag:false,
      overCountDateFlag:false,
      csvDataFlag:false,
      csvData:'',
      locationListFlag:false,
      allCount:''

    }
  },

  created() {
    this.userID = sessionStorage.getItem('userId')
    this.getUsers(this.userID)
    this.toDay = moment().format('YYYY-MM-DD');
    this.selectDay = this.$route.params.date
    //カレンダーから遷移した場合フラグを立てる
    if(typeof this.selectDay !== 'undefined') {
      this.fromCalendarFlag = true
    }
    //カレンダーの過去日付から遷移した場合フラグをたてる
    if(typeof this.$route.params.overDate !== 'undefined') {
      this.overCountDateFlag = true
    }
    //検索履歴がセッションストレージにあればフラグを立てる
    if(sessionStorage.getItem('estateListSearch') !== null) {
      this.isSessionStorageFlag = true
    }
    this.s3 = new aws.S3({
      accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.VUE_APP_AWS_S3_REGION
    });
    this.s3Bucket = process.env.VUE_APP_AWS_S3_BUCKET
  },
  mounted() {
    this.showLoader = true
    this.showLoaderSecond = true
    this.search.changeContent = 1
    //limit値がセッションストレージにあれば取得
    if(sessionStorage.getItem('estateLimit') != null) {
      this.limit = Number(sessionStorage.getItem('estateLimit'))
    }
    //ページ数がセッションストレージにあれば取得
    if(sessionStorage.getItem('estateCurrentPage') != null) {
      this.currentPage = Number(sessionStorage.getItem('estateCurrentPage'))
    }

    switch(this.fromCalendarFlag) {
      //カレンダーから遷移した場合
      case true:
        this.search.document.push(1,2)
        switch(this.overCountDateFlag) {
          //過去撮影分以外だった場合
          case false:
            this.search.statusCode.push('00','01','04','06','07','09')
            this.search.user = this.$route.params.userID
            this.search.searchFrom = this.selectDay
            this.search.searchTo = this.selectDay
            if(Array.isArray(this.$route.params.progress)) {
              this.search.progress =  (this.$route.params.progress)
            } else {
              this.search.progress.push(this.$route.params.progress)
            }
            for(let i = 0;i < this.estateKinds.length;i++) {
              this.search.estateKinds.push(this.estateKinds[i].id)
            }
            this.search.checkType = (this.$route.params.type)
            sessionStorage.setItem('estateListSearch',JSON.stringify(this.search))
            this.getLists();
          break;
          //過去撮影分
          case true:
            this.search.statusCode.push('00','01','04','06','07','09')
            this.search.searchTo = moment(this.selectDay).add(-1,'d').format('YYYY-MM-DD')
            this.search.searchFrom = ''
            this.search.user = this.$route.params.userID
            for(let i = 0;i < this.estateKinds.length;i++) {
              this.search.estateKinds.push(this.estateKinds[i].id)
            }
            if(Array.isArray(this.$route.params.progress)) {
              this.search.progress =  (this.$route.params.progress)
            } else {
              this.search.progress.push(this.$route.params.progress)
            }
            this.search.checkType = (this.$route.params.type)
            sessionStorage.setItem('estateListSearch',JSON.stringify(this.search))
            this.getLists();
          break;
        }
        break;
      //直接遷移時
      case false:
        if(sessionStorage.getItem('estateListSort') != null ) {
          if(JSON.parse(sessionStorage.getItem('estateListSort')) != 0) {
            this.isSessionSortFlag = true
          }
        }
        switch(this.isSessionStorageFlag) {
          //セッションストレージ格納済み
          case true:
            this.search = JSON.parse(sessionStorage.getItem('estateListSearch'))
            this.getLists();
            break;
          //セッションストレージ未格納
          case false:
            for(let i = 0;i < this.estateKinds.length;i++) {
              this.search.estateKinds.push(this.estateKinds[i].id)
            }
            this.search.document.push(1,2)
            this.search.statusCode.push('00','01','04','06','07','09')
            this.search.progress.push(1)
            this.search.checkType = (0)
            this.search.user = this.userID
            sessionStorage.setItem('estateListSearch',JSON.stringify(this.search))
            this.getLists();
          break;
        }
      break;
    }
    this.display = this.limit

  },
  methods: {
    //csv出力
    getCSVData() {
      this.csvData = ''
      this.csvDataFlag = true
      this.searchEstateList()
    },
    //s3のcsvデータダウンロード
    async downloadCSV(csvData) {
      try {
        await this.s3.getObject({
          Bucket: this.s3Bucket,
          Key: csvData
        }).promise()
        .then(response => {
          console.log(response)
          let blob = new Blob([response.Body], {type: 'text/csv'});
          fileSaver.saveAs(blob, csvData);
        })
        .catch(error => {
          throw error
        });
      } catch(error) {
        console.log(error)
        this.$swal({
          icon: 'error',
          html:`CSVダウンロードエラー`,
          confirmButtonText:"閉じる"
        })
        return
      }
      this.deleteCSVObject(csvData)
    },
    //s3のcsvデータ削除
    async deleteCSVObject(csvData) {
      try {
        await this.s3.deleteObject({
          Bucket: this.s3Bucket,
          Key: csvData
        }).promise()
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          throw error
        });
      } catch(error) {
        console.log(error)
        this.$swal({
          icon: 'error',
          html:`CSV削除エラー`,
          confirmButtonText:"閉じる"
        })
        return
      }
    },
    //撮影番号での検索
    getShootNo(estateID) {
      this.showLoader = true
      this.showLoaderSecond = true
      this.estateLists = []
      this.search.estateID = estateID
      this.search.statusCode = []
      this.search.estateKinds = []
      this.search.process = []
      this.search.progress = []
      this.search.statusCode.push('00','01','04','06','07','09')
      this.search.searchTo = ''
      this.search.searchFrom = ''
      for(let estateKind of this.estateKinds) {
        this.search.estateKinds.push(estateKind.id)
      }
      for(let selectProcess of this.selectProcesses) {
        this.search.process.push(selectProcess.id)
      }
      this.search.progress.push(1,2,3)
      this.search.user = ''
      sessionStorage.setItem('estateListSearch',JSON.stringify(this.search))
      this.sortEstateList('shooteingCount','desc')
      // this.getEstateList(this.search,offset,this.limit,this.searchSort);
    },
    //写真登録ページへ遷移
    pushEstatePhotoForm(
      estateID,
      locationID,
      shootingType,
      shootingDate,
      userID,
      shootingID,
      typeID,
      shootingProgressID,
      processID
    ) {
      if(shootingDate != null) {
        shootingDate = moment(shootingDate).format('YYYY-MM-DD 00:00:00');
        if(shootingType == 1) {
          this.$router.push({
            name:'PropertyInsideForm',
            params: {
              estateID:estateID,
              locationID:locationID,
              shootingType:shootingType,
              shootingDate:shootingDate,
              userID:userID,
              shootingID:shootingID
            }
          })
        } else if(shootingType == 0) {
          this.$router.push({
            name:'ExteriorForm',
            params: {
              estateID:estateID,
              locationID:locationID,
              shootingType:shootingType,
              shootingDate:shootingDate,
              progressID:shootingID,
              typeID:typeID,
              shootingProgressID:shootingProgressID,
              processID:processID
            }
          })
        }
      } else {
        this.$swal({
          icon: 'error',
          title:'撮影予定日を設定して下さい。',
          confirmButtonText:"閉じる"
        })
        return
      }
    },
    //画像拡大表示
    showEstateImage(estateImage,locationName,estateName) {
      if(estateImage !== this.noImage) {
        // web用と詳細でパスを変えていた模様。業プロTOPを参照するので不要
        // estateImage =estateImage.replace('/resizephoto/','/photo/')
        this.photoLocationName = locationName
        this.photoEstateName = estateName
        this.photoEstateImage = estateImage
        this.$bvModal.show('ShowEstatePhotoModal')
      }
    },

    //チェックボックス一括チェック
    selectAllIntrospection() {
      if(this.isEstateAllSelect) {
        this.checkUpdate = []
        this.isEstateAllSelect = false
      } else {
        this.checkUpdate = []
        for(let key in this.estateLists) {
          this.checkUpdate.push(this.estateLists[key].inShootingPrpgressID)
        }
        this.isEstateAllSelect = true
      }
    },

    //物件一覧絞り込み
    searchEstateList() {
      // console.log(this.searchSort)
      if(this.search.searchFrom > this.search.searchTo && this.search.searchFrom !== '') {
        this.$swal({
          icon: 'error',
          title:'絞り込み期間が不正です。',
          html:`撮影予定日の絞り込み開始日付は、`+'<br>'+`終了日付以前で設定してください。`,
          confirmButtonText:"閉じる"
        })
        return
      } else {
        if(this.search.progress.length !== 0) {
          if(this.search.statusCode.length !== 0) {
            if(this.search.estateKinds.length !== 0) {
              if(!this.csvDataFlag) {
                this.showLoader = true
                this.showLoaderSecond = true
                this.estateLists = []
                sessionStorage.setItem('estateListSearch',JSON.stringify(this.search))
                sessionStorage.setItem('estateListSort',JSON.stringify(this.searchSort))
                this.currentPage = 1
                this.searchOption = true
              }
              let offset = 0
              if(this.searchSort.length != 0) {
                this.getEstateList(this.search,offset,this.limit,this.searchSort);
              } else {
                this.getEstateList(this.search,offset,this.limit);
              }

            } else {
              this.$swal({
                icon: 'error',
                title:'種別を選択してください。',
                confirmButtonText:"閉じる"
              })
              return
            }
          } else {
            this.$swal({
              icon: 'error',
              title:'状態を選択してください。',
              confirmButtonText:"閉じる"
            })
            return
          }
        } else {
          this.$swal({
            icon: 'error',
            title:'進捗を選択してください。',
            confirmButtonText:"閉じる"
          })
          return
        }
      }
    },

    handleAfterUpdate({estateVals, estateID}){
      estateVals.forEach(estateVal => {
        this.estateLists.forEach(estate =>{
          if(estate.cEstateID === estateVal.estateID){
            if(estateVal.note != undefined){
              estate.txExterior = estateVal.note
            }
          }
          if(estate.inShootingPrpgressID === estateVal.shootingPrpgressID){
            if(estateVal.shootingID != undefined){
              this.usersData.forEach(user =>{
                if(user.inUserID === Number(estateVal.shootingID)){
                  estate.vcUserLName = user.vcUserLName
                }
                return
              })
            }
            if(estateVal.shootingDate != undefined){
              estate.dtShootingDate = estateVal.shootingDate
            }
            if(estateVal.shootingType != undefined){
              estate.tiShootingType = estateVal.shootingType
            }
            if(estateVal.estateID != undefined){
              estate.cEstateID = estateVal.estateID
            }
            return
          }
          if(estate.cEstateID === estateID){
            if(estateVal.shootingDate != undefined){
              estate.dtShootingDate = estateVal.shootingDate
            }
          }
        })
      })
    },

    //物件並び替え
    sortEstateList(sort,sortType) {
      if(!this.firstSort) {
        if(this.searchSort.length != 0) {
          let isSort = ''
          isSort = this.searchSort.filter(
            (val) => {
            return val.sortColumn == sort &&
              val.sortType == sortType
          })
          //アクティブなソート以外をクリック時
          if(isSort == '') {
            let sortData = ''
            sortData = this.searchSort.filter(
              (val) => {
              return val.sortColumn == sort
            })
            sortData.sortType = sortType
            if(sortData == '') {
              this.searchSort.push({
                sortColumn:sort,
                sortType:sortType
              })
              let sortFlag = sort + sortType
              this.sort.forEach(elm => {
                Object.keys(elm).forEach(key => {
                  if(key === sortFlag) {
                    elm[key] = true
                  }
                })
              })
              this.searchEstateList()
              return
            } else {
              this.searchSort.splice(this.searchSort.indexOf(sortData[0]),1)
              this.searchSort.push({
                sortColumn:sort,
                sortType:sortData.sortType
              })
              let oldSortFlag = sortData[0].sortColumn + sortData[0].sortType
              let sortFlag = sort + sortType
              this.sort.forEach(elm => {
                Object.keys(elm).forEach(key => {
                  if(key === sortFlag) {
                    elm[key] = true
                  }
                  if(key == oldSortFlag) {
                    elm[key] = false
                  }
                })
              })
              this.searchEstateList()
              return
            }
          //アクティブなソートをクリック時
          } else {
            let sortFlag = sort + sortType
            this.sort.forEach(elm => {
              Object.keys(elm).forEach(key => {
                if(key === sortFlag) {
                  elm[key] = false
                }
              })
            })
          }
          this.searchSort.splice(this.searchSort.indexOf(isSort[0]),1)
          this.searchEstateList()
          return
        } else {
          this.searchSort.push({
            sortColumn:sort,
            sortType:sortType
          })
          let sortFlag = sort + sortType
          this.sort.forEach(elm => {
            Object.keys(elm).forEach(key => {
              if(key === sortFlag) {
                elm[key] = true
              }
            })
          })
          this.searchEstateList()
          return
        }
      } else {
        for(let searchSort of this.searchSort) {
          this.sort.forEach(elm => {
            Object.keys(elm).forEach(key => {
              if(key === searchSort.sortColumn + searchSort.sortType) {
                elm[key] = true
              }
            })
          })
        }
        this.firstSort = false

        let offset = 0
        this.getEstateList(this.search,offset,this.limit,this.searchSort);
        return
      }
    },

    //市の一覧リンクからの住所検索
    searchAddress(address) {
      this.search.address = address
      this.searchEstateList();
    },
    //撮影予定日の更新モーダルオープン
    showShootDateModal(estateID,shootingID,shootDate,progressID) {
      this.selectProgressID = progressID
      this.selectEstateID = estateID
      this.selectShootDate = shootDate
      this.selectShootingProgressID = String(shootingID)
      this.$bvModal.show('UpdateShootScheduleModal')
    },

    //担当者更新のモーダルオープン
    showUserModal(estateID,shootingID,shootingType) {
      this.selectEstateID = estateID
      this.updatePageType = 'estate'
      this.selectShootingType = String(shootingType)
      this.selectShootingProgressID = String(shootingID)
      this.$bvModal.show('UpdateUserModal')
    },

    //備考更新のモーダルオープン
    showNoteModal(estateID,shootingID,note,shootingType) {
      this.selectEstateID = estateID
      this.selectShootingProgressID = String(shootingID)
      this.selectShootingType = String(shootingType)
      this.selectNote = note
      this.$bvModal.show('UpdateNoteModal')
    },

    //PDF表示モーダルオープン
    async ShowPDFModal(pdf) {
      this.pdfCurrentPage = 1
      this.pdfData = ''
      await this.$bvModal.show('ShowPDFModal')
      this.pdfData = pdf
    },
    //テーブル全項目表示
    showAllHeaders() {
      for(let i = 0;i < this.tableHeaders.length;i++) {
        this.tableHeaders[i].showHeader = true
      }
    },
    //テーブル表示項目
    showTableHeader() {
      //内観が選択されたときテーブルヘッダーを内観用に非表示にする
      if(this.typeIntroFlag && !this.typeExteriorFlag) {
        for(let tableHeader of this.tableHeaders) {
          if(tableHeader.type == 'intro') {
            tableHeader.showHeader = true
          } else {
            tableHeader.showHeader = false
          }
        }
      }
      //外観が選択されたときテーブルヘッダーをすべて表示する
      if(this.typeExteriorFlag ) {
        for(let tableHeader of this.tableHeaders) {
            tableHeader.showHeader = true
          if(tableHeader.value == 'keyInformation') {
            tableHeader.showHeader = false
          }
          if(tableHeader.value == 'contactAddress') {
            tableHeader.showHeader = false
          }
        }
      } else {
        for(let tableHeader of this.tableHeaders) {
          if(tableHeader.type == 'intro') {
            tableHeader.showHeader = true
          } else {
            tableHeader.showHeader = false
          }
        }
      }
    },

    //ページネーションクリック時現在のページ番号取得
    paginateClickCallback(pageNum) {
      this.currentPage = Number(pageNum);
      sessionStorage.setItem('estateCurrentPage', JSON.stringify(this.currentPage))
      this.showLoader = true
      this.showLoaderSecond = true
      this.estateLists = []
      this.$scrollTo('#tabletop');
      let offset = (this.currentPage - 1) * this.limit;
      if(this.searchSort.length != 0) {
        this.getEstateList(this.search,offset,this.limit,this.searchSort);
      } else {
        this.getEstateList(this.search,offset,this.limit);
      }
    },
    //表示件数変更
    changeLimit() {
      this.limit = this.display
      this.showLoader = true
      this.showLoaderSecond = true
      this.estateLists = []
      sessionStorage.setItem('estateLimit', JSON.stringify(this.limit))
      this.currentPage = 1
      let offset = (this.currentPage - 1) * this.limit;
      if(this.searchSort.length != 0) {
        this.getEstateList(this.search,offset,this.limit,this.searchSort);
      } else {
        this.getEstateList(this.search,offset,this.limit);
      }
    },
    //進捗管理リスト取得
    getLists() {
      let offset = (this.currentPage - 1) * this.limit;
      sessionStorage.setItem('estateOffset', offset)
      sessionStorage.setItem('estateLimit', this.limit)
      if(this.isSessionSortFlag) {
        this.searchOption = true
        this.searchSort = JSON.parse(sessionStorage.getItem('estateListSort'))
        this.firstSort = true
        this.sortEstateList()
      } else {
        if(this.searchOption) {
          return this.searchEstateList(this.search,offset,this.limit)
        } else {
          return this.getEstateList(this.search,offset, this.limit);
        }
      }

    },
    // データクリア
    clearList() {
      this.search.estateID = ''
      this.search.estateName = ''
      this.search.locationID = ''
      this.search.locationName = ''
      this.search.companyName = ''
      this.search.address  = ''
      this.search.statusCode = []
      this.search.statusCode.push('00','01','04','06','07','09')
      this.search.progress = []
      this.search.progress.push(1)
      this.search.user = this.userID
      this.search.estateKinds = []
      for(let estateKind of this.estateKinds) {
        this.search.estateKinds.push(estateKind.id)
      }
      this.search.process = []
      this.search.searchTo = ''
      this.search.searchFrom = ''
      this.search.note = ''
      this.search.changeContent = 1
      this.search.checkType = 0
      this.search.document = []
      this.search.document.push(1,2)
      //this.search.searchLimits = []
      sessionStorage.setItem('estateListSearch',JSON.stringify(this.search))
    },
  },

  computed: {
    //総ページ数の計算
    getPaginateCount() {
//      let allCount = this.estateLists.length
      let allCount = ''
      if(this.locationListFlag) {
        allCount = this.locationCount
      } else {
        allCount = this.estateCount
      }
      return Math.ceil(allCount / this.limit);
    }
  },

  watch:{
    //更新用チェックボックス全チェック
    'checkUpdate':function() {
      if (this.checkUpdate.length !== this.estateLists.length) {
        this.isEstateAllSelect = false
      } else {
        this.isEstateAllSelect = true
      }
    },
    'currentPage':function() {
      sessionStorage.setItem('estateCurrentPage', this.currentPage)
    },
    'search.checkType':function() {
      if(this.search.checkType == 0) {
        this.typeExteriorFlag = true
      } else {
        this.typeExteriorFlag = false
      }
    }
  }


}
</script>
<style scoped>

.btn_link:hover {
  opacity: 0.8;
}
.btn_link {
  cursor: pointer;
}

.table_link {
  cursor: pointer;
}
.table_link:hover {
  background-color:gainsboro;
}
</style>
